<template>
  <div class="Userhl_ygkecheng Userhl_rightbox">
    <ul class="Userhl_baomingrwtype">
      <li
        v-for="(item, index) in bigtype"
        :key="index"
        @click="bigetypeclick(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul class="Userhl_baomingrwzt">
      <li
        v-for="(item, index) in moneytype"
        :key="index"
        @click="moneytypeclick(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <ul class="Userhl_zuopinul">
      <li
        class="Userhl_zuopinulli"
        v-for="(item, index) in list"
        @click="videolink(item.p_id)"
        :key="index"
      >
        <div class="Userhl_zuopinulliimg">
          <img :src="item.title_pic" alt="" />
        </div>
        <p class="Userhl_zuopinulliname">{{ item.title }}</p>
        <p class="Userhl_zuopinullitype">
          课程数量 <span>（{{ item.video_num }}）</span>
        </p>
        <div class="Userhl_zuopinullibtm">
          <div class="Userhl_zuopinullibtmlvideo">
            <img :src="item.userpic" alt="" />
            <span>{{ item.username }}</span>
          </div>
          <div class="Userhl_zuopinullibtmr">
            更新时间：{{ item.create_time }}
          </div>
        </div>
        <div class="Userhl_zuopinullimm" v-if="item.money > 0">
          <img
            src="https://task.hozoin.cn/Works/20210112/sj-9232138810.png"
            alt=""
          />
          <span>￥{{ item.money }}</span>
        </div>
        <div class="Userhl_zuopinullimm" v-if="item.money == 0">
          <img
            src="https://task.hozoin.cn/Works/20210112/sj-5398800896.png"
            alt=""
          /><span>免费</span>
        </div>
      </li>
      <li class="Userhl_zuopinulli_zero" v-if="Userhl_zuopinulli_zero"></li>
      <li class="lizero" v-if="lizero">
        <img
          src="https://task.hozoin.cn/Works/20210426/sj-3936605600.png"
          alt=""
        />
      </li>
    </ul>
    <el-button class="linkbtn" plain @click="linkxm('/Jiaoyu')">查看更多课程</el-button>
  </div>
</template>
<script>
import $ from "jquery";
$(document).on("click", ".Userhl_baomingrwtype li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
$(document).on("click", ".Userhl_baomingrwzt li", function () {
  $(this).css({
    color: "#4FC6FF",
    "border-bottom": "2px solid #4FC6FF",
  });
  $(this).siblings().css({
    color: "#333",
    border: "none",
  });
  $(this).siblings().eq(0).css({
    color: "#999",
  });
});
export default {
  components: {},
  data() {
    return {
      lizero: false,
      userinfo: {},
      Userhl_zuopinulli_zero: false,
      img: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      limit: 9,
      list: [],
      charge: "", //1免费 2收费
      p_type: "", //22环境设计 23产品设计 24视觉传达设计
      bigtype: [
        {
          name: "专业分类",
          id: "",
        },
        {
          name: "全部",
          id: "",
        },
        {
          name: "环境艺术",
          id: "22",
        },
        {
          name: "产品设计",
          id: "23",
        },
        {
          name: "视觉传达设计",
          id: "24",
        },
      ],
      moneytype: [
        {
          name: "收费情况",
          id: "",
        },
        {
          name: "全部",
          id: "",
        },
        {
          name: "免费课程",
          id: "1",
        },
        {
          name: "收费课程",
          id: "2",
        },
      ],
    };
  },
  methods: {
    linkxm(link) {
      this.$router.push({ path: link });
    },
    axiosqq(page) {
      var that = this;
      this.lizero = true;
      that.list = [];
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/myVideo",
        method: "post",
        data: {
          page,
          limit: that.limit,
          word_type: "2",
          charge: that.charge,
          p_type: that.p_type,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          this.lizero = false;
          var num = res.data.result.list.length % 3;
          if (num == 2) {
            that.Userhl_zuopinulli_zero = true;
          }
          that.list = res.data.result.list;
        } else {
          this.lizero = true;
          // that.$message(res.data.messages);
        }
      });
    },
    bigetypeclick(id) {
      this.p_type = id;
      this.axiosqq(1);
    },
    moneytypeclick(id) {
      this.charge = id;
      this.axiosqq(1);
    },
    videolink(id) {
      this.$router.push({ path: "/jiaoyu_heji", query: { data: id } });
    },
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.axiosqq(1);
  },
};
</script>
